<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">


      <!-- Left Text-->
      <b-col
        lg="2"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            class="sosty-terms-and-conditions-image"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="10"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <h1><strong>POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES SOSTY S.A.S.</strong></h1>
          </b-card-title>
          <br>
          <b-card-text class="mb-2">
            <ol style="font-size:20px;color:black" type="A">
              <li>
                <h2>
                  <strong>Introducción</strong>
                </h2>
                  <h5> La Constitución Política de Colombia consagra el derecho que tienen todas las personas a conocer, actualizar y rectificar la información que exista sobre ellas en bases de datos o archivos de entidades públicas o privadas. Igualmente, ordena a quienes tienen Datos Personales de terceros a respetar los derechos y garantías previstos en la Constitución cuando se recolecta, trata y circula esta clase de información.
                    <br>
                    <br>SOSTY S.A.S. (en adelante “La Compañía”) está comprometida con el cumplimiento de la regulación en materia de protección de Datos Personales y con el respeto de los derechos de los Titulares de la información. Por esta razón, adopta la presente Política de Privacidad y Tratamiento de Datos Personales (en adelante, “La Política”) de obligatoria aplicación en todas las actividades que involucren Tratamiento de Datos Personales y de obligatorio cumplimiento por parte de La Compañía, sus Administradores, Colaboradores y los terceros con quien éste se relacione contractual o comercialmente. El uso de los Servicios ofrecidos en nuestra Plataforma (en adelante “los Servicios”) y/o la aceptación expresa e inequívoca de la presente Política, implica la aceptación de los titulares de la presente Política y su autorización para los usos y otros tratamientos que aquí se describen.
                  </h5> 
                  <br>
              </li>

              <li>                  
                <h2>
                  <strong>Objetivo</strong>
                </h2>
                  <h5>El objetivo de la presente Política es garantizar los derechos que en virtud de la ley y la constitución tienen los Titulares de los Datos Personales, identificar las finalidades para las que serán tratados los Datos Personales, así como la definición de los lineamientos para la atención de consultas y reclamos de los Titulares de los Datos Personales.</h5>
                  <br>
              </li>

              <li>  
                <h2>
                  <strong>Alcance</strong>
                </h2>
                  <h5>Esta Política es de obligatorio y estricto cumplimiento por parte de la Compañía, sus directores, administradores, colaboradores y los demás terceros quienes la representan o actúan por ella, o con los que la Compañía tiene algún tipo de vínculo, sea legal, comercial o convencional.</h5>              
                <br>
              </li>

              <li>
                <h2>
                  <strong>Definiciones</strong>
                </h2>
                  <h5>Los términos aquí definidos serán usados a lo largo del presente documento y su significado será el que se establece a continuación: Las palabras y términos que se definen a continuación tendrán estos significados:</h5>              
                    <ul>
                      <h5>
                        <li><strong>Aviso de privacidad:</strong> es una de las opciones de comunicación verbal o escrita que brinda la Compañía para darle a conocer a los titulares de la información, la existencia y las formas de acceder a las políticas de tratamiento de la información y el objetivo de su recolección y uso.</li>
                        <br>
                        <li><strong>Autorización:</strong> consentimiento previo, expreso e informado del Titular del dato para llevar a cabo el Tratamiento. Esta puede ser i) escrita; ii) verbal o; iii) conductas inequívocas que permitan concluir de forma razonable que el Titular aceptó el Tratamiento de sus datos.</li>
                        <br>
                        <li><strong>Base de Datos:</strong> conjunto organizado de Datos Personales que sean objeto de Tratamiento, electrónico o no, cualquiera que fuere la modalidad de su formación, almacenamiento, organización y acceso.</li>
                        <br>
                        <li><strong>Consulta:</strong>solicitud del Titular del Dato Personal, de las personas autorizadas por éste, o las autorizadas por ley, para conocer la información que reposa sobre él en las Bases de Datos de la Compañía.</li>
                        <br>
                        <li><strong>Dato Personal:</strong>cualquier información vinculada o que pueda asociarse a una o varias personas naturales, determinadas o determinables.</li>
                        <br>
                        <li><strong>Dato Personal Privado:</strong>dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.</li>
                        <br>
                        <li><strong>Dato Personal Público:  </strong>dato calificado como tal según los mandatos de la ley o de la Constitución Política y todos aquellos que no sean semiprivados, privados o sensibles. Por ejemplo: los datos contenidos en documentos públicos, registros públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidos a reserva, los relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público. Son públicos los Datos Personales existentes en el registro mercantil de las Cámaras de Comercio.</li>
                        <br>
                        Asimismo, son datos públicos, los que, en virtud de una decisión del Titular o de un mandato legal, se encuentren en archivos de libre acceso y consulta. Estos datos pueden ser obtenidos y ofrecidos sin reserva alguna y sin importar si hacen alusión a información general, privada o personal.
                        <br>
                        <br>
                        <li><strong>Dato personal semiprivado</strong>dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su Titular sino a cierto sector o grupo de personas, o a la sociedad en general. Por ejemplo: el dato referente al cumplimiento e incumplimiento de las obligaciones financieras o los datos relativos a las relaciones con las entidades de la seguridad social.</li>
                        <br>
                        <li><strong>Dato personal sensible:</strong>dato que afecta la intimidad de la persona o cuyo uso indebido puede generar su discriminación. Por ejemplo: aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición, datos relativos a la salud, a la vida sexual y los datos biométricos (huellas dactilares, íris, etc), entre otros.</li>
                        <br>
                        <li><strong>Encargado:</strong>persona natural o jurídica que realiza el Tratamiento de datos por cuenta del Responsable del Tratamiento.</li>
                        <br>
                        <li><strong>Incidente de seguridad:</strong>Se refiere a la violación de los códigos de seguridad o la pérdida, robo y/o acceso no autorizado de información de una base de datos.</li>
                        <br>
                        <li><strong>Plataforma: </strong>Se refiere a Sosty identificada con el dominio: https://www.sosty.co/</li>
                        <br>
                        <li><strong>Reclamo: </strong>solicitud del Titular del dato o las personas autorizadas por éste o por la ley para corregir, actualizar o suprimir sus Datos Personales o cuando adviertan que existe un presunto incumplimiento del régimen de protección de datos.</li>
                        <br>
                        <li><strong>Responsable: </strong>Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos</li>
                        <br>
                        <li><strong>Requisito de procedibilidad: </strong>paso previo que debe surtir el Titular antes de interponer una queja ante la Superintendencia de Industria y Comercio. Este consiste en una reclamación directa al Encargado o Responsable de sus Datos Personales.</li>
                        <br>
                        <li><strong>Titular del dato: </strong>significa la persona natural cuyos datos personales sean objeto de Tratamiento.</li>
                        <br>
                        <li><strong>Tratamiento: </strong>cualquier operación o conjunto de operaciones sobre Datos Personales como, la recolección, el almacenamiento, el uso, la circulación, transferencia, transmisión, actualización o supresión de los Datos Personales, entre otros.</li>
                        <br>
                        El Tratamiento puede ser nacional (dentro de Colombia) o internacional (fuera de Colombia).
                        <br>
                        <br>
                        <li><strong>Transmisión: </strong>Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del Responsable. </li>
                        <br>
                        <li><strong>Transferencia: </strong>Tratamiento de Datos Personales que tiene lugar cuando el Responsable y/o Encargado del Tratamiento de Datos Personales, envía los Datos Personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.</li>
                        <br>
                      </h5>
                    </ul>
                  <br>
              </li>

              <li>
                <h2>
                  <strong>Principios para la Recolección de los Datos Personales</strong>
                </h2>
                  <ul>
                    <h5>
                      <li><strong>Principio de libertad</strong></li>
                        <h5>Salvo norma legal en contrario, la recolección de los datos sólo puede ejercerse con la autorización previa, expresa e informada del Titular. Los datos personales no podrán ser obtenidos o divulgados sin el previo consentimiento del Titular, o en ausencia de mandato legal o judicial que releve el consentimiento. 
                        <br>Se deberá informar al titular del dato de manera clara, suficiente y previa acerca de la finalidad de la información suministrada y, por tanto, no podrá recopilarse datos sin la especificación sobre la finalidad de los mismos. 
                        <br>
                        <br>No se podrán utilizar medios engañosos o fraudulentos para recolectar y realizar Tratamiento de datos personales.
                        </h5>
                        <br>
                      <li><strong>Principio de limitación de la recolección</strong></li>
                      <h5>Sólo deben recolectarse los Datos Personales que sean estrictamente necesarios para el cumplimiento de las finalidades del Tratamiento, de tal forma que se encuentra prohibido el registro y divulgación de datos que no guarden estrecha relación con el objetivo del Tratamiento. En consecuencia, debe hacerse todo lo razonablemente posible para limitar el procesamiento de datos personales al mínimo necesario. Es decir, los datos deberán ser: (i) adecuados, (ii) pertinentes y (iii) acordes con las finalidades para las cuales fueron previstos.</h5>                  
                    </h5>
                  </ul>
                <br>
              </li>

              <li>
                <h2>
                  <strong>Principios para el Tratamiento de Datos Personales</strong>
                </h2>
                <h5>En el desarrollo, interpretación y aplicación de la presente Política, se aplicarán los siguientes principios:</h5>
                  <ul>
                    <h5>
                      <li><strong>Legalidad</strong></li>
                      <h5>El tratamiento de datos personales es una actividad reglada que debe sujetarse a lo establecido en la ley y en las demás disposiciones que la desarrollen.</h5>
                      <br>
                      <li><strong>Finalidad</strong></li>
                      <h5>El tratamiento de datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.</h5>
                      <br>
                      <li><strong>Libertad</strong></li> 
                      <h5>El tratamiento de datos personales solo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.</h5>
                      <br>
                      <li><strong>Veracidad o calidad</strong></li> 
                      <h5>La información personal sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible, en este sentido, se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</h5>
                      <br>
                      <li><strong>Transparencia</strong></li> 
                      <h5>En el tratamiento de datos personales debe garantizarse el derecho del Titular a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</h5>
                      <br>
                      <li><strong>Acceso y circulación restringida</strong></li>   
                      <h5>El tratamiento de datos personales está sujeto a los límites que se derivan de la naturaleza de los mismos, de las disposiciones de ley y la Constitución. En este sentido, su tratamiento solo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en ley. Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido solo a los Titulares o terceros autorizados conforme a la ley.</h5>               
                      <br>
                      <li><strong>Seguridad</strong></li> 
                      <h5>La información personal sujeta a tratamiento por el Responsable del Tratamiento o Encargado del Tratamiento a que se refiere la ley, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</h5>
                      <br>
                      <li><strong>Confidencialidad</strong></li> 
                      <h5>Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo solo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los términos de la misma.</h5>
                      <br>
                      <li><strong>Temporalidad</strong></li> 
                      <h5>Los datos personales se conservarán únicamente por el tiempo razonable y necesario para cumplir la finalidad del tratamiento y las exigencias legales o instrucciones de las autoridades de vigilancia y control u otras autoridades competentes. Los datos serán conservados cuando ello sea necesario para el cumplimiento de una obligación legal o contractual. Para determinar el término del tratamiento se considerarán las normas aplicables a cada finalidad y los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información.</h5>
                      <br>
                      <li><strong>No discriminación</strong></li> 
                      <h5>Queda prohibido realizar cualquier acto de discriminación por las informaciones recaudadas en las bases de datos o archivos.</h5>
                      <br>
                      <li><strong>Reparación</strong></li> 
                      <h5>Es obligación indemnizar los perjuicios causados por las posibles fallas en el tratamiento de datos personales.</h5>
                    </h5>
                  </ul>
                <br>
              </li>

              <li>
                <h2>
                  <strong>Datos Personales suministrados</strong>
                </h2>
                <h5>La Compañía podrá pedir expresamente a los titulares o recolectar de su comportamiento los datos que sean necesarios para cumplir las finalidades de las bases de datos, los cuales son -entre otros- los siguientes:</h5>
                  <ul>
                    <h5>
                      <li>Nombre y apellidos;</li>
                      <br>
                      <li>Nacionalidad;</li>
                      <br>
                      <li>Estado civil;</li>   
                      <br>
                      <li>Tipo de Documento de Identificación;</li>   
                      <br>
                      <li>Número de identificación; </li>                  
                      <br>
                      <li>Libreta militar;</li>   
                      <br>
                      <li>Fecha y lugar de nacimiento;</li>   
                      <br>
                      <li>Dirección de correspondencia y/o residencia y/o de facturación;</li>   
                      <br>
                      <li>Teléfono de contacto;</li>                  
                      <br>
                      <li>Correo electrónico;</li>   
                      <br>
                      <li>Referencias de cualquier naturaleza;</li>   
                      <br>
                      <li>Antecedentes comerciales o información biográfica, financiera, judicial, disciplinaria y familiar, y la relacionada con otras compañías o con entidades públicas;</li>   
                      <br>
                      <li>Fotografías recientes;</li>   
                      <br>
                      <li>Sexo;</li>   
                      <br>
                      <li>Género identitario;</li>   
                      <br>
                      <li>Datos de medios transaccionales tales como tarjetas de crédito o cuentas bancarias, únicamente para efectos de realizar los cobros por los Servicios de la Plataforma, o para poner a disposición de los usuarios de la Plataforma medios transaccionales;</li>   
                      <br>
                      <li>Autorizaciones para desarrollar actividades relacionadas con los servicios ofrecidos en la Plataforma;</li>   
                      <br>
                      <li>Historial de compras;</li>   
                      <br>
                      <li>Historial de casos abiertos con servicio al cliente;</li>   
                      <br>
                      <li>Contenido favorito;</li>   
                      <br>
                      <li>Historial de preguntas a expertos en el sitio web;</li>   
                      <br>
                      <li>Historial crediticio y capacidad de pago;</li>   
                      <br>
                      <li>Permisos y autorizaciones requeridos para proveer servicios;</li>   
                      <br>
                      <li>Razón o denominación social de la empresa con la que se encuentra vinculado; </li>   
                      <br>
                      <li>Identificación tributaria;</li>   
                      <br>
                      <li>Datos sobre la salud mental o física;</li>   
                      <br>
                      <li>Lugar de trabajo cargo o profesión del cónyuge o compañero permanente de empleados y contratistas y de sus parientes hasta el cuarto grado de consanguinidad, segundo de afinidad y/o primero civil;</li>   
                      <br>
                      <li>Cualquier otro dato que fuere necesario para lograr las finalidades descritas;</li>   
                      <br>
                      <li>Cualquier otro dato relevante para la prestación de los Servicios que ofrece La Compañía.</li>   
                      <br>
                    </h5>
                  </ul>
                  <br>
              </li>

              <li>
                <h2>
                  <strong>Tratamiento al cual serán sometidos los Datos Personales</strong>
                </h2>
                <h5>La Compañía realizará el Tratamiento de los Datos Personales de acuerdo con las condiciones establecidas por el Titular, la ley o las entidades públicas para el cumplimiento de las actividades propias de su objeto social como pueden ser la contratación o ejecución de los Servicios que ésta ofrece.
                <br>
                <br>El Tratamiento de los Datos Personales se podrá realizar a través de medios físicos, automatizados o digitales de acuerdo con el tipo y forma de recolección de la información.
                </h5>
                  <br>
              </li>    

              <li>
                <h2>
                  <strong>Finalidades como Responsable</strong>
                </h2>
                <h5>La Compañía, en su calidad de Responsable, también podrá tratar los Datos Personales, entre otros, para los siguientes fines:</h5>
                  <ul>
                    <h5>
                      <li>Ejercer su derecho de conocer de manera suficiente al Titular con quien se propone entablar relaciones, prestar servicios y valorar el riesgo presente o futuro de las mismas relaciones y servicios. Efectuar las gestiones pertinentes para el desarrollo de la etapa precontractual, contractual y post contractual con la Compañía, respecto de cualquiera de los Servicios ofrecidos por ésta, que haya o no adquirido o respecto de cualquier relación negocial subyacente que tenga con ella, así como dar cumplimiento a la ley colombiana o extranjera y a las órdenes de autoridades judiciales o administrativas;</li>
                      <br>
                      <li>Realizar actividades de mercadeo, ventas y promocionales, telemarketing (mercadeo telefónico), servicio al cliente, actividades de activación de marca, premios y promociones, directamente o a través de terceros derivados de alianzas comerciales o de cualquier vínculo;</li>
                      <br>
                      <li>Implementar estrategias de relacionamiento con clientes, proveedores, accionistas y otros terceros con los cuales la Compañía tenga relaciones contractuales o legales;</li>
                      <br>
                      <li>Realizar invitaciones a eventos, mejorar servicios, y todas aquellas actividades asociadas a la relación comercial o vínculo existente con la Compañía, o aquel que llegare a tener;</li>
                      <br>
                      <li>Gestionar trámites (solicitudes, quejas, reclamos), efectuar encuestas de satisfacción respecto de los bienes y servicios ofrecidos por la Compañía, o empresas vinculadas y los aliados comerciales de ésta;</li>
                      <br>
                      <li>Dar a conocer, transferir y/o trasmitir Datos Personales dentro y fuera del país a las compañías matrices, filiales o subsidiarias de La Compañía o a terceros como consecuencia de un contrato, ley o vínculo lícito que así lo requiera o para implementar servicios de computación en la nube;</li>
                      <br>
                      <li>Los datos que se recolecten o almacenen sobre los empleados de la Compañía, mediante el diligenciamiento de formatos, vía telefónica, o con la entrega de documentos serán tratados para todo lo relacionado con cuestiones laborales de orden legal o contractual. En virtud de lo anterior, la Compañía utilizará los Datos Personales para los siguientes fines: (1) Dar cumplimiento a las leyes como, entre otras, de derecho laboral, seguridad social, pensiones, riesgos profesionales, cajas de compensación familiar (Sistema Integral de Seguridad Social) e impuestos; (2) Cumplir las instrucciones de las autoridades judiciales y administrativas competentes; (3) Implementar las políticas y estrategias laborales y organizacionales;</li>
                      <br>
                      <li>Ordenar, catalogar, clasificar, dividir o separar la información suministrada por los Titulares de datos. Verificar, corroborar, comprobar, validar, investigar o comparar la información suministrada por los Titulares de datos, con cualquier información de que disponga legítimamente, como relaciones comerciales. Acceder, consultar, comparar y evaluar toda la información que sobre el Titular se encuentre almacenada en las bases de datos de cualquier central de riesgo crediticio, financiero, de antecedentes judiciales o de seguridad, de naturaleza estatal o privada, nacional o extranjera, o cualquier base de datos comercial o de servicios, que permita establecer de manera integral e histórica completa, el comportamiento que como deudor, usuario, cliente, garante, endosante, afiliado, beneficiario, suscriptor, contribuyente y/o como Titular de servicios financieros, comerciales o de cualquier otra índole;</li>
                      <br>
                      <li>Para fines de seguridad, mejoramiento del servicio, la experiencia en los servicios, los Datos Personales podrán ser utilizados, entre otros, como prueba en cualquier tipo de proceso;</li>
                      <br>
                      <li>Conocer, almacenar y procesar toda la información suministrada por los Titulares de datos en una o varias bases de datos, en el formato que estime más conveniente;</li>
                      <br>
                      <li>Registrar, almacenar y actualizar la información y los Datos Personales en sus Bases de Datos;</li>
                      <br>
                      <li>Realizar análisis estadísticos (incluso de los datos sensibles) e investigaciones comerciales, de riesgos, de mercado, interbancarias y financieras;</li>
                      <br>
                      <li>Adelantar los procedimientos de transcripción, radicación y cobro de subsidios por incapacidad temporal frente a las entidades del Sistema de Seguridad Social;</li>
                      <br>
                      <li>Adelantar procesos de selección, los cuales podrán incluir entrevistas, sometimiento a pruebas de conocimiento y a pruebas sicotécnicas, práctica de visitas domiciliarias, así como el acceso a hojas de vida y las comunicaciones con el candidato por cualquier vía;</li>
                      <br>
                      <li>Consultar, solicitar o verificar la información y Datos Personales del Titular en bases de datos de entidades públicas o privadas, o con personas naturales o jurídicas, o en buscadores públicos, redes sociales o publicaciones físicas o electrónicas, bien fuere en Colombia o en el exterior;</li>
                      <br>
                      <li>Procesar solicitudes de los usuarios de la Plataforma;</li>
                      <br>
                      <li>Realizar todas las gestiones de orden tributario, contable, fiscal y de facturación;</li>
                      <br>
                      <li>Cumplir el objeto de la relación laboral, comercial o civil que se hubiere adquirido con los titulares;</li>
                      <br>
                      <li>El cumplimiento del objeto social de La Compañía y de las funcionalidades de la Plataforma;</li>
                      <br>
                      <li>Cumplir con la propuesta de valor y el nivel de servicio ofrecido a los usuarios de la Plataforma.</li>
                      <br>
                    </h5>
                  </ul>
                  <br>
              </li>

              <li>
                <h2>
                  <strong>Finalidades como Encargado</strong>
                </h2>
                <h5>La Compañía podrá actuar como Encargada del tratamiento de Datos Personales que conozca a través de sus aliados estratégicos u otras organizaciones, para lo cual estos últimos se comprometen a solicitar la correspondiente Autorización de Tratamiento de Datos Personales por parte de los Titulares. 
                  <br>
                  <br>El tratamiento y finalidad de los datos que La Compañía realiza en nombre de los Responsables (aliados estratégicos u otras organizaciones) será el establecido en sus Políticas de Tratamiento de Datos Personales y el siguiente: 
                </h5>
                  <ul>
                    <h5>
                      <li>El desarrollo, ejecución y cumplimiento del objeto social de la Compañía y el propósito de la Plataforma </li>
                      <br>
                      <li>El cumplimiento de deberes legales, contables, comerciales y regulatorios.</li>
                      <br>
                      <li>El acceso a los Servicios prestados por la Plataforma. </li>
                      <br>
                    </h5>
                  </ul>
                  <br>
              </li>      

              <li>
                <h2>
                  <strong>Uso de Datos Personales bajo esta Política</strong>
                </h2>
                <h5>La Compañía solo tratará los datos personales y otra información de los Titulares para las finalidades descritas y los usos autorizados en esta Política o en las leyes vigentes.</h5>
                  <br>
              </li>

              <li>
                <h2>
                  <strong>Limitaciones temporales al Tratamiento de Datos</strong>
                </h2>
                <h5>La Compañía podrá actuar como Encargada del tratamiento de Datos Personales que conozca a través de sus aliados estratégicos u otras organizaciones, para lo cual estos últimos se comprometen a solicitar la correspondiente Autorización de Tratamiento de Datos Personales por parte de los Titulares. 
                <br>
                <br>El tratamiento y finalidad de los datos que La Compañía realiza en nombre de los Responsables (aliados estratégicos u otras organizaciones) será el establecido en sus Políticas de Tratamiento de Datos Personales y el siguiente: 
                </h5>
                  <br>
              </li>

              <li>
                <h2>
                  <strong>Derechos de los Titulares de los datos</strong>
                </h2>
                <h5>Los Titulares de los Datos personales, tienen derecho a:</h5>
                  <ul>
                    <h5>
                      <li>Conocer, actualizar y rectificar sus Datos Personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado. Para el efecto es necesario establecer previamente la identificación de la persona para evitar que terceros no autorizados accedan a los datos del Titular.</li>
                      <br>
                      <li>Solicitar prueba de la autorización otorgada a la Compañía, salvo que se trate de uno de los casos en los que no es necesaria la autorización, de conformidad con lo previsto en el artículo 10 de la ley 1581 de 2012.</li>                  
                      <br>
                      <li>Ser informado por la Compañía, previa solicitud, respecto del uso que le ha dado a sus Datos Personales.</li>
                      <br>
                      <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley y las demás normas que la modifiquen, adicionen o complementen.</li>
                      <br>
                      <li>Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.</li>                  
                      <br>
                      <li>La solicitud de supresión de la información y la revocatoria de la autorización no procederá cuando el Titular tenga un deber legal o contractual de permanecer en la base de datos del Responsable o Encargado.</li>
                      <br>
                      <li>Acceder en forma gratuita a sus Datos Personales que hayan sido objeto de Tratamiento.</li>                  
                    </h5>
                  </ul>
                  <br>
              </li>

              
              <li>
                <h2>
                  <strong>Deberes de la Compañía cuando obra como Responsable o Encargado del Tratamiento de Datos Personales</strong>
                </h2>
                <h5>La Compañía está obligada a cumplir los deberes impuestos por la ley. Por ende, debe obrar de tal forma que cumpla con los siguientes deberes:</h5>
                  <ul>
                    <h5>
                      <li>Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio de los derechos mencionados en esta Política.</li>
                      <br>
                      <li>Observar los principios establecidos en esta política en el Tratamiento de Datos Personales</li>                  
                      <br>
                      <li>Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</li>
                      <br>
                      <li>Actualizar la información cuando sea necesario.</li>
                      <br>
                      <li>Rectificar los Datos Personales cuando ello sea procedente.</li>                  
                      <br>
                      <li>Suministrar al Encargado del Tratamiento únicamente los Datos Personales cuyo Tratamiento esté previamente autorizado.</li>
                      <br>
                      <li>Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</li>  
                      <br>
                      <li>Comunicar de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.</li>                
                      <br>
                      <li>Informar de manera oportuna al Encargado del Tratamiento las rectificaciones realizadas sobre los Datos Personales para que éste proceda a realizar los ajustes pertinentes.</li>                
                      <br>
                      <li>Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.</li>                
                      <br>
                      <li>Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.</li>                
                      <br>
                      <li>Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.</li>                
                      <br>
                      <li>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>                
                      <br>                                    
                    </h5>
                  </ul>
                  <br> 
              </li>

              <li>
                <h2>
                  <strong>Aviso de Privacidad</strong>
                </h2>
                <h5>Mediante el aviso de privacidad se informa al Titular, la información relativa a la existencia de las políticas y procedimientos de Tratamiento de Datos Personales contenida en esta política, así como las características del Tratamiento que se le dará a los Datos Personales, y contendrá, cuando menos, la siguiente información: (i) identidad, domicilio y datos de contacto del Responsable  o Encargado  del Tratamiento; (ii) tipo de Tratamiento al cual serán sometidos los Datos Personales y su finalidad; y (iii) los mecanismos generales dispuestos por el Responsable para que el Titular conozca la Política de Tratamiento y los mecanismos para la consulta de los Datos Personales del Titular.
                <br>
                <br>La Compañía conservará copia del modelo de aviso de privacidad que se transmite a los Titulares mientras se lleve a cabo el Tratamiento de los Datos Personales y perduren las obligaciones que de éste deriven. Para el almacenamiento del modelo, La Compañía o quien éste designe podrá emplear medios informáticos, electrónicos o cualquier otra tecnología. El aviso de privacidad de tratamiento de los datos personales está para la disposición y consulta a través de la Página Web de La Plataforma.
                </h5>
                <br>
              </li>

              <li>
                <h2>
                  <strong>De la autorización</strong>
                </h2>
                <ol style="font-size:18px;color:black">
                    <li><h4><strong>Autorización para el Tratamiento de Datos y forma de obtención. </strong></h4>
                    <h5>
                    Los obligados a cumplir esta Política deberán obtener de parte del Titular su autorización previa, expresa e informada para recolectar y tratar sus Datos Personales. Esta obligación no es necesaria cuando se trate de datos de naturaleza pública.
                    <br>
                    <br>Para obtener la autorización, es necesario informarle al Titular de los Datos Personales de forma clara y expresa lo siguiente:            
                    </h5>            
                      <ul>
                        <h5>
                          <li>El Tratamiento al cual serán sometidos sus Datos Personales y la finalidad del mismo;</li>
                          <br>
                          <li>El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando estas versen sobre datos sensibles o sobre los datos de las niñas, niños y adolescentes;</li>                  
                          <br>
                          <li>Los derechos que le asisten como Titular previstos en el Artículo 8 de la Ley 1581 de 2012;</li>
                          <br>
                          <li>La identificación, dirección física o electrónica de la Compañía.</li>                                 
                        </h5>
                      </ul>
                      <br>
                      <h5>La Autorización del Titular debe obtenerse a través de cualquier medio que pueda ser objeto de consulta posterior, tal como la página web, formularios, formatos, actividades, concursos, presenciales o en redes sociales, formato de PQR, mensajes de datos o Apps, email, teléfono, registro o autenticación en la Plataforma, entre otras.
                        <br>
                        <br>La autorización también podrá obtenerse a partir de conductas inequívocas del Titular del Dato que permitan concluir de manera razonable que éste otorgó su consentimiento para el Tratamiento. Dichas conductas deben ser muy claras de manera que no admitan duda o equivocación sobre la voluntad de autorizar el Tratamiento.
                      </h5>
                      <br>
                      </li> 

                    <li><h4><strong>Manifestaciones de los Titulares.</strong></h4></li>
                    <h5>
                    Los titulares manifiestan que:             
                    </h5>            
                      <ul>
                        <h5>
                          <li>Mediante el suministro voluntario de alguno de los datos personales en la forma señalada en el numeral anterior, la autorización expresa verbal o por escrito, el registro o creación de un perfil o cuenta en la Plataforma, o la autenticación  o ingreso a la Plataforma por medio de la autenticación con la cuenta del titular suscrita a un tercero, incluyendo, pero sin limitarse a Microsoft, Google o Facebook, el titular autoriza expresa e inequívocamente a La Compañía para recolectar datos personales y cualquier otra información que suministre, así como para realizar el tratamiento sobre sus datos personales, de conformidad con esta Política y la ley.El Tratamiento al cual serán sometidos sus Datos Personales y la finalidad del mismo;</li>
                          <br>
                          <li>Se les informó acerca de las finalidades para las cuales se utilizarán los datos sensibles recolectados, los cuales se encuentran descritos en el título I de esta Política.</li>                  
                          <br>
                          <li>Entienden que son datos sensibles aquellos que afectan la intimidad del titular o cuyo uso indebido puede generar discriminación, así como los de origen racial o étnico, orientación política, convicciones religiosas o filosóficas, pertenencia a sindicatos, organizaciones sociales, datos relacionados con el estado de salud, la vida sexual y los datos biométricos.</li>
                          <br>
                          <li>Comprenden las medidas de seguridad que La Compañía implementa para brindar protección a los datos personales que recolecta y, por tanto, aceptan las mismas.  </li>                                 
                        </h5>
                      </ul>
                      <br>

                    <li><h4><strong>Autorización para Tratamiento de Datos Sensibles.</strong></h4></li>
                    <h5>
                    Cuando se trate de la recolección de datos sensibles se deben cumplir los siguientes requisitos:            
                    </h5>            
                      <ul>
                        <h5>
                          <li>La autorización debe ser explícita;</li>
                          <br>
                          <li>Se debe informar al Titular que no está obligado a autorizar el Tratamiento de dicha información;</li>                  
                          <br>
                          <li>Se debe informar de forma explícita y previa al Titular cuáles de los datos que serán objeto de Tratamiento son sensibles y la finalidad del mismo;</li>
                          <br>
                          <li>Se requiere autorización por parte del representante o tutor para el Tratamiento de datos de niños, niñas y adolescentes.</li>                                 
                        </h5>
                      </ul>
                      <br>

                    <li><h4><strong>Autorización para Tratamiento de datos de niños, niñas y adolescentes</strong></h4></li>
                    <h5>
                      Cuando se trate de la recolección y Tratamiento de datos de niños, niñas y adolescentes se deben cumplir los siguientes requisitos:            
                    </h5>            
                      <ul>
                        <h5>
                          <li>La autorización debe ser otorgada por personas que estén facultadas para representar los niños, niñas o adolescentes. El representante de los niños, niñas o adolescentes deberá garantizarles el derecho a ser escuchados y valorar su opinión del Tratamiento teniendo en cuenta la madurez, autonomía y capacidad de los niños, niñas y adolescentes para entender el asunto;</li>
                          <br>
                          <li>Se debe informar que es facultativo responder preguntas sobre datos de los niños, niñas y adolescentes;</li>                  
                          <br>
                          <li>La Compañía debe asegurar que el Tratamiento de los Datos Personales de los niños, niñas y adolescentes será realizado respetando sus derechos, razón por la cual, en las actividades comerciales y de mercadeo que realice, deberá contar con la autorización previa, expresa e informada del padre o la madre o del representante legal del niño, niña o adolescente.</li>
                          <br>                    
                        </h5>                  
                      </ul>
                        <h5>
                          Sin perjuicio de lo anterior, La Compañía únicamente tratará los datos de los menores de edad para los fines exclusivos de la prestación de los Servicios, o cualquier otro fin que sea estrictamente necesario para desarrollar el objeto social de la Compañía. 
                        </h5>
                      <br>

                    <li><h4><strong>Autorización para nuevos usos</strong></h4></li>
                    <h5>
                      La Compañía podrá solicitar autorización de los titulares para nuevos usos de sus datos o información, para lo cual publicará los cambios en la presente Política de Tratamiento en su página web o en cualquier medio que estime conveniente según el caso.
                    </h5>                            
                      <br>
                </ol>
              </li>

              <li>
                <h2>
                  <strong>Almacenamiento de Datos Personales</strong>
                </h2>              
                  <h5>
                    El Titular autoriza expresamente a la Compañía para que almacene los datos de la forma que considere más oportuna y cumpla con la seguridad requerida para la protección de los datos de los titulares.
                  </h5>
                  <br>
              </li>

              <li>
                <h2>
                  <strong>Transferencias nacionales o internacionales de Datos Personales</strong>
                </h2>              
                  <h5>
                    La Compañía podrá realizar transferencias de datos a otros Responsables del Tratamiento cuando así esté autorizado por el Titular de la información, por la ley o por un mandato administrativo o judicial.
                    <br>Cuando la transferencia sea internacional, La Compañía garantizará que el país receptor tendrá los estándares adecuados de protección de datos personales.
                  </h5>
                  <br>  
              </li>

              <li>
                <h2><strong>Transmisiones nacionales o internacionales de Datos Personales</strong></h2>
                  <h5>
                    La Compañía podrá enviar o transmitir datos a uno o varios Encargados ubicados dentro o fuera del territorio de la República de Colombia cuando así esté autorizado por el Titular de la información, por la ley o por un mandato administrativo o judicial. Para todos los efectos se entiende que con la aceptación de esta política los titulares consienten que la Compañía transfiera o transmita internacionalmente los datos a sus proveedores de servicios computacionales en la nube, frente a los cuales la Compañía certifica que se encuentran ubicados en una jurisdicción con un nivel adecuado de protección (i.e., Estados Unidos en virtud del marco Safe Harbor).
                    <br>
                    <br>La Compañía transmitirá datos a sus proveedores y otras partes interesadas, para las siguientes finalidades: 
                  </h5>            
                    <ul>
                      <h5>
                        <li>Realizar servicios a nombre de la Plataforma, incluido el mantenimiento de cuentas, brindar servicio al cliente, procesar o cumplir con Servicios y transacciones, verificar la información del Usuario, procesar pagos, brindar servicios de publicidad o marketing, brindar servicios analíticos o brindar servicios similares en nombre de la empresa o proveedor de servicio.</li>
                        <br>
                        <li>Realizar auditorías relacionadas con una interacción actual y transacciones simultáneas.</li>                  
                        <br>
                        <li>Detectar incidentes de seguridad, protegerse contra actividades maliciosas, engañosas, fraudulentas o ilegales y enjuiciar a los responsables de esa actividad.</li>
                        <br> 
                        <li>Depuración para identificar y reparar errores que perjudican la funcionalidad prevista existente.</li>                   
                        <br>
                        <li>Realizar actividades para verificar o mantener la calidad o seguridad de un servicio o dispositivo que poseemos, fabricamos o está asociado a los servicios que presta la Plataforma</li>                   
                        <br>
                        <li>Realización de investigaciones internas para el desarrollo y la demostración tecnológica.</li>                   
                        <br>                    
                        <li>Cualquier otra finalidad que sea necesaria para cumplir con el objeto social de la Compañía.</li>                   
                        <br>                       
                      </h5>
                    </ul>  
              </li>

              <li>
                <h2><strong>Procedimiento para que los Titulares puedan ejercer sus derechos</strong></h2>
                  <h5>
                    Para el ejercicio pleno y efectivo de los derechos que le asisten a los Titulares de los Datos Personales, o a las personas legitimadas por ley para tal efecto, la Compañía dispone del correo electrónico indicado más adelante, a través del cual podrán presentar todas las consultas, quejas y/o reclamos asociados al derecho de Habeas Data.
                    <br>
                    <br>Todas las solicitudes que realicen las personas legitimadas para conocer los Datos Personales que reposan en la Compañía se canalizarán a través del mencionado correo electrónico en el cual constará la fecha de recibo de la consulta y la identidad del solicitante.
                    <br>
                    <br>El reclamo debe dirigirse a SOSTY S.A.S.  y contener como mínimo la siguiente información:
                  </h5>            
                    <ul>
                      <h5>
                        <li>Nombre e identificación del Titular del dato o la persona legitimada.</li>
                        <br>
                        <li>Descripción precisa y completa de los hechos que dan lugar al reclamo.</li>                  
                        <br>
                        <li>Pretensiones.</li>
                        <br> 
                        <li>Dirección física o electrónica para remitir la respuesta e informar sobre el estado del trámite.</li>                   
                        <br>
                        <li>Dirección física o electrónica para remitir la respuesta e informar sobre el estado del trámite.</li>                   
                        <br>
                        <li>Documentos y demás pruebas pertinentes que quiera hacer valer.</li>                                                          
                      </h5>                  
                    </ul>
                    <h5>Una vez recibida la solicitud se procederá con la verificación de la identidad del peticionario o de la legitimación de éste para tal fin. La respuesta a la consulta deberá comunicarse al solicitante en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro de dicho término, se deberá informar al interesado los motivos de la demora y la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
                      <br>
                      <br>Si la solicitud o el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a su recepción para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.
                    </h5>
                  <br> 
              </li>

              <li>
                <h2><strong>Persona o área responsable de la protección de los Datos Personales</strong></h2>
                  <h5>
                    El área encargada de atender las peticiones, consultas y reclamos de los titulares para ejercer sus derechos a conocer, actualizar, rectificar y suprimir sus datos y revocar su autorización es el área de atención al usuario. Las peticiones, consultas y reclamos podrán ser remitidas al correo electrónico hola@sosty.com o en la siguiente URL https://www.sosty.co/contacto.                 
                  </h5> 
                  <br>
              </li>

              <li>
                <h2><strong>Datos del Responsable del Tratamiento</strong></h2>
                  <h5>
                    Razón social: SOSTY S.A.S.
                    <br>NIT: 901.466.394-5
                    <br>Dirección: [CL 137 55 32 T1 UNIT 802, BOGOTÁ D.C. COLOMBIA]
                    <br>Correo Electrónico: hola@sosty.com
                    <br>Teléfono: [+57(310)799-3625]
                    <br>Página web: https://www.sosty.co/
                    <br>
                  </h5> 
                  <br> 
              </li>

              <li>
                <h2><strong>Políticas de seguridad de la información</strong></h2>
                  <h5>
                    La Compañía adoptará las medidas técnicas, administrativas y humanas necesarias para procurar la seguridad de los Datos Personales a los que les da Tratamiento, protegiendo la confidencialidad, integridad, uso, acceso no autorizado y/o fraudulento a éstos. Para tal fin, ha implementado protocolos de seguridad de obligatorio cumplimiento para todo el Personal que tenga acceso a estos datos y/o a los sistemas de información.
                    <br>
                    <br>Las políticas internas de seguridad bajo las cuales se conserva la información del Titular para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento serán incluidas en el Programa Integral de Gestión de Datos Personales de la Compañía. El titular acepta expresamente esta forma de protección y declara que la considera conveniente y suficiente para todos los propósitos.
                    <br>
                    <br>La Compañía protege la información mediante varios mecanismos como, entre otros, los siguientes: 
                    <br>                
                  </h5>            
                    <ul>
                      <h5>
                        <li>Protección de acceso a los datos mediante contraseñas y roles de diferentes niveles de autoridad.</li>
                        <br>
                        <li>Protección de las contraseñas.</li>                  
                        <br>
                        <li>Aseguramiento del nivel de complejidad de las contraseñas de los usuarios.</li>
                        <br> 
                        <li>Rastreo de todas las actividades realizadas con las plataformas y sus datos.</li>                   
                        <br>
                        <li>Procedimientos de recuperación y redundancia.</li>                   
                        <br>
                        <li>Almacenamiento de las copias de respaldo.</li>                                       
                        <br>                                         
                        <li>Cifrado y protección por contraseña de los computadores desde los que se realiza la manipulación de los datos.</li>                    
                      </h5>                  
                    </ul>
                    <h5>
                      Adicionalmente, la Compañía cuenta con una política de seguridad de información y datos personales de obligatorio cumplimiento.
                    </h5>
                  <br> 
              </li>

              <li>
                <h2><strong>Quejas ante la Superintendencia de Industria y Comercio</strong></h2>
                  <h5>
                    El Titular, sus causahabientes o apoderados, deberán agotar el trámite de consulta ante La Compañía o quien éste designe, con anterioridad a la presentación de cualquier queja ante la Superintendencia de Industria y Comercio.
                    <br>                
                  </h5>  
                  <br>  
              </li>

              <li>
                <h2><strong>Término de la Autorización </strong></h2>
                  <h5>
                    El término del Tratamiento de los Datos Personales será desde que se otorga la autorización hasta el día en que La Compañía se disuelva y se liquide o hasta que se termine la finalidad para la cual fueron recolectados los Datos Personales.
                    <br>                
                  </h5>
                  <br>      
              </li>

              <li>
                <h2><strong>Ajustes a la política de Tratamiento de Datos Personales</strong></h2>
                  <h5>
                    Con miras a mantener la vigencia de la política, la Compañía puede ajustarla y modificarla, indicando la fecha de la actualización en la Plataforma o mediante el empleo de otros medios, como mensajes de datos, materiales físicos, etc.
                    <br>                
                  </h5> 
                  <br>      
              </li>                                                    

              <li>
                <h2><strong>Legislación Vigente</strong></h2>
                  <h5>
                    La legislación nacional vigente en materia de protección de datos personales está contenida en la ley 1581 de 2012, el decreto 1377 de 2013 y la ley 1266 de 2008 y las normas que lo modifiquen, complementen o subroguen.
                    <br>
                    <br>                                
                  </h5> 
                  <br>
                  <br>
              </li>

            </ol>

              <h2 align="right">Vigencia: 5 de abril de 2021</h2>                           
                                 
                
                                        

              

          </b-card-text>


          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Volver al login
            </b-link>
          </p>
          
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/sosty-login.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>